import React, { Component, Fragment } from "react";
import { NavLink } from "reactstrap";
import { connect } from "react-redux";
import { logout } from "../../../actions/authActions";
import PropTypes from "prop-types";
import "./Logout.css";

export class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired
  };

  render() {
    return (
      <Fragment>
        <NavLink
          onClick={() => {
            this.props.logout();
            if (window.innerWidth < 768) this.props.collapseMenu();
          }}
          href="#"
        >
          Logout
        </NavLink>
      </Fragment>
    );
  }
}

export default connect(null, { logout })(Logout);
