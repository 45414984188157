import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AdicionarProjecto from "./AdicionarProjecto/AdicionarProjecto";
import FiltroCliente from "./FiltroCliente/FiltroCliente";
import MostrarArquivados from "./MostrarArquivados/MostrarArquivados";
import "./Header.css";

class Header extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool
  };

  render() {
    return (
      <div>
        {this.props.isAuthenticated ? (
          // Quando autenticado
          <div className="header">
            {/* Titulo : Projectos */}
            <h1 className="">Projectos</h1>

            {/* Filtrar por cliente */}
            {this.props.user.tipo === "Administrador" ||
            this.props.user.tipo === "Editor" ? (
              <div className="filtroCliente">
                <FiltroCliente />
              </div>
            ) : null}

            {/* Mostrar arquivados */}
            <div className="mostrarArquivados">
              <MostrarArquivados />
            </div>

            {/* Adicionar projecto */}
            {this.props.user.tipo === "Administrador" ||
            this.props.user.tipo === "Editor" ? (
              <div className="adicionarProjecto">
                <AdicionarProjecto />
              </div>
            ) : null}
          </div>
        ) : (
          // Quando não autenticado
          <div>
            <h4 className="msgConsultar">
              Para consultar os seus projectos faça Login
            </h4>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps)(Header);
