import {
    GET_CLIENTS,
    GET_ONE_CLIENT,
    ADD_CLIENT,
    UPDATE_CLIENT,
    DELETE_CLIENT,
    CLIENTS_LOADING
  } from '../actions/types';
  
  const initialState = {
    clients: [],
    loading: false
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case GET_CLIENTS:
        return {
          ...state,
          clients: action.payload,
          loading: false
        };
      case GET_ONE_CLIENT:
        return {
          ...state,
          clients: action.payload,
          loading: false
        };
      case DELETE_CLIENT:
        return {
          ...state,
          clients: state.clients.filter(client => client._id !== action.payload)
        };
      case ADD_CLIENT:
        return {
          ...state,
          clients: [action.payload, ...state.clients]
        };
      case UPDATE_CLIENT:
        return {
          ...state,
          clients: state.clients.map(client => client._id === action.payload._id ? action.payload : client)
        };
      case CLIENTS_LOADING:
        return {
          ...state,
          loading: true
        };
      default:
        return state;
    }
  }
  