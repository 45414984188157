import { DELETE_FILTRO, UPDATE_FILTRO } from "../actions/types";

const initialState = {
  filtro: "Filtrar por cliente"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FILTRO:
      return {
        ...state,
        filtro: action.payload
      };
    case DELETE_FILTRO:
      return {
        ...state,
        filtro: "Filtrar por cliente"
      };
    default:
      return state;
  }
}
