import axios from "axios";
import {
  GET_ONE_ESTADO,
  GET_ESTADOS,
  ADD_ESTADO,
  UPDATE_ESTADO,
  DELETE_ESTADO,
  ESTADOS_LOADING
} from "./types";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";

export const getEstados = () => (dispatch, getState) => {
  dispatch(setEstadosLoading());
  axios
    .get("/api/estados", tokenConfig(getState))
    .then(res =>
      dispatch({
        type: GET_ESTADOS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const getOneEstado = name => (dispatch, getState) => {
  dispatch(setEstadosLoading());
  axios
    .get(`/api/estados/${name}`, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: GET_ONE_ESTADO,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const addEstado = estado => (dispatch, getState) => {
  axios
    .post("/api/estados", estado, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: ADD_ESTADO,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const updateEstado = (id, estado) => (dispatch, getState) => {
  axios
    .put(`/api/estados/${id}`, estado, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: UPDATE_ESTADO,
        payload: estado
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const deleteEstado = id => (dispatch, getState) => {
  axios
    .delete(`/api/estados/${id}`, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: DELETE_ESTADO,
        payload: id
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const setEstadosLoading = () => {
  return {
    type: ESTADOS_LOADING
  };
};
