import React, { Component } from "react";
import AppNavbar from "./components/Navbar/AppNavbar";
import ListaProjectos from "./components/ListaProjectos/ListaProjectos";
import Header from "./components/Header/Header";
import { Container } from "reactstrap";

import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/authActions";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Footer from "./components/Footer/Footer";

class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <AppNavbar />
          <Container className="corpo">
            <Header />
            <ListaProjectos />
          </Container>
          <Container>
            <Footer />
          </Container>
        </div>
      </Provider>
    );
  }
}

export default App;
