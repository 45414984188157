import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { deleteItem } from "../../../../actions/itemActions";
import { updateClient, deleteClient } from "../../../../actions/clientActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "./ApagarProjecto.css";

class ApagarProjecto extends Component {
  state = {
    modal: false
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    user: PropTypes.object.isRequired
  };

  onDeleteClick = id => {
    const { items } = this.props.item;
    const { clients } = this.props.client;
    items.filter(item =>
      item._id === id
        ? clients.filter(client =>
            client.name === item.cliente
              ? // Decrementa um projecto activo ao cliente
                this.props.updateClient(client._id, {
                  ...client,
                  projectosActivos: client.projectosActivos - 1
                })
              : null
          )
        : null
    );
    this.props.deleteItem(id);
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    return (
      <div>
        {this.props.isAuthenticated ? (
          !this.props.proj.arquivado && this.props.user.tipo !== "Cliente" ? (
            <Button
              className="btn mr-1"
              color="danger"
              size="sm"
              onMouseDown={this.toggle}
            >
              <FontAwesomeIcon icon={faTrashAlt} inverse />
            </Button>
          ) : (
            <Button
              className="btn mr-1"
              color="secondary"
              size="sm"
              outline
              disabled
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )
        ) : null}

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Apagar {this.props.track}?
          </ModalHeader>
          <ModalBody>
            <p>Está prestes a apagar este {this.props.track}.</p>
            <p>
              Este processo é irreversivel. Tem a certeza que quer continuar?
            </p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.toggle}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={this.onDeleteClick.bind(this, this.props.id)}
            >
              Apagar
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  item: state.item,
  client: state.client,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  deleteItem,
  updateClient,
  deleteClient
})(ApagarProjecto);
