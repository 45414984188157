export const GET_ITEMS = "GET_ITEMS";
export const GET_CLIENT_ITEMS = "GET_CLIENT_ITEMS";
export const ADD_ITEM = "ADD_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const ARCHIVE_ITEM = "ARCHIVE_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const ITEMS_LOADING = "ITEMS_LOADING";

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GET_ONE_CLIENT = "GET_ONE_CLIENT";
export const GET_CLIENTS = "GET_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const CLIENTS_LOADING = "CLIENTS_LOADING";

export const GET_ONE_ESTADO = "GET_ONE_ESTADO";
export const GET_ESTADOS = "GET_ESTADOS";
export const ADD_ESTADO = "ADD_ESTADO";
export const UPDATE_ESTADO = "UPDATE_ESTADO";
export const DELETE_ESTADO = "DELETE_ESTADO";
export const ESTADOS_LOADING = "ESTADOS_LOADING";

export const UPDATE_FILTRO = "UPDATE_FILTRO";
export const DELETE_FILTRO = "DELETE_FILTRO";
