import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { getItems, getClientItems } from "../../actions/itemActions";
import { getEstados } from "../../actions/estadoActions";
import PropTypes from "prop-types";
import ListHeader from "./ListHeader/ListHeader";
import Projecto from "./Projecto/Projecto";
import { Container, ListGroup } from "reactstrap";
import "./ListaProjectos.css";

class ListaProjectos extends Component {
  static propTypes = {
    getItems: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool
  };

  componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props;
    if (prevProps.isAuthenticated !== isAuthenticated && isAuthenticated) {
      if (
        this.props.user.tipo === "Administrador" ||
        this.props.user.tipo === "Editor"
      ) {
        this.props.getItems();
        this.props.getEstados();
      } else {
        this.props.getClientItems(this.props.user.name);
        this.props.getEstados();
      }
    }
  }

  render() {
    const { items } = this.props.item;
    const { clients } = this.props.clientes;

    return (
      <Container className="listContainer">
        {this.props.isAuthenticated
          ? // Apresenta só o cliente escolhido no filtro
            this.props.filtro !== "Filtrar por cliente"
            ? clients.map(cliente =>
                cliente.name === this.props.filtro ? (
                  // Lista
                  <ListGroup key={cliente._id} style={{ clear: "both" }}>
                    {/* Nome do Cliente */}
                    <h4 className="nomeCliente">{cliente.name}</h4>

                    {/* Lista */}
                    <TransitionGroup className="align-middle mb-3">
                      <CSSTransition timeout={250} classNames="fade">
                        {/* Cabeçalho de lista */}
                        <ListHeader />
                      </CSSTransition>

                      {items.map(proj =>
                        this.props.mostrarArquivados ? (
                          cliente.name === proj.cliente ? (
                            <CSSTransition
                              key={proj._id}
                              timeout={250}
                              classNames="fade"
                            >
                              {/* Projecto */}
                              <Projecto
                                id={proj._id}
                                name={proj.name}
                                proj={proj}
                              />
                            </CSSTransition>
                          ) : null
                        ) : cliente.name === proj.cliente &&
                          proj.arquivado === false ? (
                          <CSSTransition
                            key={proj._id}
                            timeout={250}
                            classNames="fade"
                          >
                            {/* Projecto */}
                            <Projecto
                              id={proj._id}
                              name={proj.name}
                              proj={proj}
                            />
                          </CSSTransition>
                        ) : null
                      )}
                    </TransitionGroup>
                  </ListGroup>
                ) : null
              )
            : // Apresenta todos os clientes que tem projectos activos
              clients.map(cliente =>
                cliente.projectosActivos > 0 || this.props.mostrarArquivados ? (
                  // Lista
                  <ListGroup key={cliente._id} style={{ clear: "both" }}>
                    {/* Nome do Cliente */}
                    <h4 className="nomeCliente">{cliente.name}</h4>

                    {/* Lista */}
                    <TransitionGroup className="align-middle mb-3">
                      <CSSTransition timeout={250} classNames="fade">
                        {/* Cabeçalho de lista */}
                        <ListHeader />
                      </CSSTransition>

                      {items.map(proj =>
                        this.props.mostrarArquivados ? (
                          cliente.name === proj.cliente ? (
                            <CSSTransition
                              key={proj._id}
                              timeout={20}
                              classNames="fade"
                            >
                              {/* Projecto */}
                              <Projecto
                                id={proj._id}
                                name={proj.name}
                                proj={proj}
                              />
                            </CSSTransition>
                          ) : null
                        ) : cliente.name === proj.cliente &&
                          proj.arquivado === false ? (
                          <CSSTransition
                            key={proj._id}
                            timeout={20}
                            classNames="fade"
                          >
                            {/* Projecto */}
                            <Projecto
                              id={proj._id}
                              name={proj.name}
                              proj={proj}
                            />
                          </CSSTransition>
                        ) : null
                      )}
                    </TransitionGroup>
                  </ListGroup>
                ) : null
              )
          : // isAuthenticated End
            null}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  item: state.item,
  clientes: state.client,
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  filtro: state.filtro.filtro,
  mostrarArquivados: state.arquivados.arquivados
});

export default connect(mapStateToProps, {
  getItems,
  getClientItems,
  getEstados
})(ListaProjectos);
