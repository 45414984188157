import axios from "axios";
import {
  GET_ONE_CLIENT,
  GET_CLIENTS,
  ADD_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  CLIENTS_LOADING
} from "./types";
import { tokenConfig } from "./authActions";
import { returnErrors } from "./errorActions";

export const getClients = () => (dispatch, getState) => {
  dispatch(setClientsLoading());
  axios
    .get("/api/clients", tokenConfig(getState))
    .then(res =>
      dispatch({
        type: GET_CLIENTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const getOneClient = name => (dispatch, getState) => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/clients/${name}`, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: GET_ONE_CLIENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const addClient = client => (dispatch, getState) => {
  axios
    .post("/api/clients", client, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: ADD_CLIENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const updateClient = (id, client) => (dispatch, getState) => {
  axios
    .put(`/api/clients/${id}`, client, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: UPDATE_CLIENT,
        payload: client
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const deleteClient = id => (dispatch, getState) => {
  axios
    .delete(`/api/clients/${id}`, tokenConfig(getState))
    .then(res =>
      dispatch({
        type: DELETE_CLIENT,
        payload: id
      })
    )
    .catch(err =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const setClientsLoading = () => {
  return {
    type: CLIENTS_LOADING
  };
};
