import React, { Component } from "react";
import { Row, ListGroupItem } from "reactstrap";
import "./ListHeader.css";

export default class ListHeader extends Component {
  render() {
    return (
      <ListGroupItem id="listHeader">
        <Row>
          {/* Acção */}
          <div className="accaoHeader">
            <label htmlFor="accao" className="mb-0">
              Acção
            </label>
          </div>

          {/* Nome do Projecto */}
          <div className="projectoHeader">
            <label htmlFor="projecto" className="px-3 mb-0">
              Projecto
            </label>
          </div>

          {/* Datas */}
          <div className="datasHeader">
            <label htmlFor="data" className="mb-0">
              Encomenda/Entrega
            </label>
          </div>

          {/* Estado (Ponto da situação) */}
          <div className="estadoHeader">
            <label htmlFor="Estado" className="mb-0">
              Estado
            </label>
          </div>
        </Row>
      </ListGroupItem>
    );
  }
}
