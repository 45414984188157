import React, { Component, Fragment } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CriarCliente from "./CriarCliente/CriarClienteModal";
import ListarClientes from "./ListarClientes/ListarClientesModal";
import RegistrarModal from "./Registrar/RegistrarModal";
import LoginModal from "./Login/LoginModal";
import Logout from "./Logout/Logout";
import logo from "../../images/logo_adriano.png";
import "./AppNavbar.css";

class AppNavbar extends Component {
  state = {
    isOpen: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;

    const authMensagem = (
      <Fragment>
        <NavItem>
          <span className="navbar-text mr-2">
            <strong>{user ? `Bem-vindo ${user.name}` : ""}</strong>
          </span>
        </NavItem>
      </Fragment>
    );

    const guestMensagem = (
      <Fragment>
        <NavItem>
          <span className="navbar-text mr-2">
            <strong>Inicie sessão no Tracking</strong>
          </span>
        </NavItem>
      </Fragment>
    );

    const adminLinks = (
      <Fragment>
        <NavItem>
          <CriarCliente />
        </NavItem>
        <NavItem>
          <ListarClientes />
        </NavItem>
        <NavItem>
          <RegistrarModal />
        </NavItem>
        <NavItem>
          <Logout collapseMenu={this.toggle} />
        </NavItem>
      </Fragment>
    );

    const editorLinks = (
      <Fragment>
        <NavItem>
          <CriarCliente />
        </NavItem>
        <NavItem>
          <ListarClientes />
        </NavItem>
        <NavItem>
          <Logout />
        </NavItem>
      </Fragment>
    );

    const clienteLinks = (
      <Fragment>
        <NavItem>
          <ListarClientes />
        </NavItem>
        <NavItem>
          <Logout />
        </NavItem>
      </Fragment>
    );

    const guestLinks = (
      <Fragment>
        <NavItem>
          <LoginModal collapseMenu={this.toggle} />
        </NavItem>
      </Fragment>
    );

    return (
      <div>
        <Navbar color="dark" dark expand="md" className="navbar">
          <Container>
            <NavbarBrand href="/">
              <img src={logo} className="logo" alt="Logotipo" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar className="flex-column">
              <Nav className="ml-auto" navbar>
                {isAuthenticated ? authMensagem : guestMensagem}
              </Nav>
              <Nav className="ml-auto" navbar>
                {isAuthenticated
                  ? (() => {
                      switch (user.tipo) {
                        case "Administrador":
                          return adminLinks;
                        case "Editor":
                          return editorLinks;
                        case "Cliente":
                          return clienteLinks;
                        default:
                          return null;
                      }
                    })()
                  : guestLinks}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AppNavbar);
