import React, { Component } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { connect } from "react-redux";
import { addItem } from "../../../actions/itemActions";
import { updateClient } from "../../../actions/clientActions";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import "./AdicionarProjecto.css";

class AdicionarProjecto extends Component {
  state = {
    modal: false,
    erro: "",
    name: "",
    estado: "Em espera",
    cliente: "Escolha cliente",
    updatedClient: {}
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      cliente: "Escolha cliente",
      erro: ""
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClick = (event, cliente) => {
    this.setState({
      cliente: event.target.innerText,
      updatedClient: cliente
    });
  };

  onSubmit = e => {
    e.preventDefault();

    if (this.state.cliente !== "Escolha cliente" && this.state.name !== "") {
      // Add item via addItem action
      const newItem = {
        name: this.state.name,
        estado: this.state.estado,
        cliente: this.state.cliente
      };
      this.props.addItem(newItem);

      // Incrementa um projecto activo ao cliente
      const updatedClient = {
        ...this.state.updatedClient,
        projectosActivos: this.state.updatedClient.projectosActivos + 1
      };
      this.props.updateClient(this.state.updatedClient._id, updatedClient);

      // Close modal
      this.toggle();
    } else {
      this.setState({
        erro: "Por favor, escolha um nome para o projecto e um cliente"
      });
    }
  };

  render() {
    const { clients } = this.props.clientes;
    return (
      <React.Fragment>
        {this.props.isAuthenticated ? (
          <div>
            <Button color="dark" onClick={this.toggle}>
              Adicionar projecto
            </Button>
          </div>
        ) : null}

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Adicionar projecto</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.onSubmit}>
              <FormGroup>
                <Label for="item">Projecto</Label>
                <Input
                  type="text"
                  name="name"
                  id="item"
                  placeholder="Adicionar projecto"
                  onChange={this.onChange}
                />

                <UncontrolledDropdown className="mt-2">
                  <Label for="Cliente">Cliente</Label>
                  <DropdownToggle
                    className="escolhaCliente"
                    outline
                    color="primary"
                  >
                    {this.state.cliente}
                    <FontAwesomeIcon icon={faCaretDown} className="my-auto" />
                  </DropdownToggle>
                  <DropdownMenu className="clienteSelect">
                    {clients.map(cliente => (
                      <DropdownItem
                        key={cliente._id}
                        onClick={event =>
                          this.handleClick.bind(this)(event, cliente)
                        }
                      >
                        {cliente.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>

                <p className="adicionarClienteErro">{this.state.erro}</p>

                <Button color="dark" className="adicionarClienteButton" block>
                  Adicionar
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  item: state.item,
  clientes: state.client,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { addItem, updateClient })(
  AdicionarProjecto
);
