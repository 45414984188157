import { UPDATE_FILTRO, DELETE_FILTRO, ARCHIVE_ITEM } from "./types";

export const updateFiltro = filtro => dispatch => {
  dispatch({
    type: UPDATE_FILTRO,
    payload: filtro
  });
};

export const deleteFiltro = () => dispatch => {
  dispatch({
    type: DELETE_FILTRO
  });
};

export const updateArquivo = () => dispatch => {
  dispatch({
    type: ARCHIVE_ITEM
  });
};
