import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateArquivo } from "../../../actions/showActions";
import { Label } from "reactstrap";
import "./MostrarArquivados.css";

class MostrarArquivados extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool
  };

  handleChange() {
    this.props.updateArquivo();
  }

  render() {
    return (
      <React.Fragment>
        {this.props.isAuthenticated ? (
          <div className="arquivados">
            <input type="checkbox" onChange={this.handleChange.bind(this)} />
            <Label>
              <span className="fullText">Mostrar Arquivados</span>
              <span className="mediumText">Arquivados</span>
              <span className="shortText">Arquivo</span>
            </Label>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { updateArquivo })(MostrarArquivados);
