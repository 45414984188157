import React, { Component } from "react";
import { connect } from "react-redux";
import { updateItem } from "../../../../actions/itemActions";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import { Button } from "reactstrap";
import "./Data.css";

class Data extends Component {
  static propTypes = {
    updateItem: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    valorData: PropTypes.string,
    user: PropTypes.object.isRequired
  };

  handleChangeStart(data, proj) {
    const updatedItem = {
      ...proj,
      dataEncomenda: data
    };
    this.props.updateItem(proj._id, updatedItem);
  }

  handleChangeEnd(data, proj) {
    const updatedItem = {
      ...proj,
      dataEntrega: data
    };
    this.props.updateItem(proj._id, updatedItem);
  }

  formatDate(d) {
    var data = new Date(d);
    var dia, mes, ano;
    data.getDate() < 10 ? (dia = "0" + data.getDate()) : (dia = data.getDate());
    data.getMonth() < 9
      ? (mes = "0" + (data.getMonth() + 1))
      : (mes = data.getMonth() + 1);
    ano = data.getFullYear();
    return dia + "/" + mes + "/" + ano;
  }

  render() {
    return (
      <div className="">
        {/* Data da encomenda */}
        <DatePicker
          disabled={
            this.props.proj.arquivado || this.props.user.tipo === "Cliente"
              ? true
              : false
          }
          customInput={
            <Button
              outline
              color={!this.props.proj.arquivado ? "primary" : "secondary"}
              size="sm"
              style={{ opacity: this.props.proj.arquivado ? 0.65 : 1 }}
            >
              {this.formatDate.bind(this)(this.props.proj.dataEncomenda)}
            </Button>
          }
          todayButton={"Hoje"}
          dateFormat="dd-MM-yyyy"
          selected={new Date(this.props.proj.dataEncomenda)}
          selectsStart
          startDate={new Date(this.props.proj.dataEncomenda)}
          endDate={new Date(this.props.proj.dataEntrega)}
          onChange={data =>
            this.handleChangeStart.bind(this)(data, this.props.proj)
          }
          locale={pt}
          className="mr-0 escolherData"
        />

        {/* Data de Entrega  */}
        <DatePicker
          disabled={
            this.props.proj.arquivado || this.props.user.tipo === "Cliente"
              ? true
              : false
          }
          customInput={
            <Button
              outline
              color={!this.props.proj.arquivado ? "dark" : "secondary"}
              size="sm"
              style={{ opacity: this.props.proj.arquivado ? 0.65 : 1 }}
            >
              {this.formatDate.bind(this)(this.props.proj.dataEntrega)}
            </Button>
          }
          todayButton={"Hoje"}
          dateFormat="dd-MM-yyyy"
          selected={new Date(this.props.proj.dataEntrega)}
          selectsEnd
          startDate={new Date(this.props.proj.dataEncomenda)}
          endDate={new Date(this.props.proj.dataEntrega)}
          onChange={data =>
            this.handleChangeEnd.bind(this)(data, this.props.proj)
          }
          minDate={new Date(this.props.proj.dataEncomenda)}
          locale={pt}
          className="ml-1 escolherData"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  item: state.item,
  user: state.auth.user
});

export default connect(mapStateToProps, { updateItem })(Data);
