import React, { Component } from "react";
import logo from "../../images/logo_nerys.svg";
import "./Footer.css";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <a href="https://nerys.dev" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Logotipo" className="footerLogo" />
        </a>
        <a href="mailto:geral@adrianorodrigues.com" className="contacto">
          Contacto
        </a>
      </div>
    );
  }
}
