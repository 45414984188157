import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { updateItem } from "../../../../actions/itemActions";
import { updateClient } from "../../../../actions/clientActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import "./ArquivarProjecto.css";

class ArquivarProjecto extends Component {
  state = {
    modal: false
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    user: PropTypes.object.isRequired
  };

  onArchiveClick = proj => {
    // Actualiza numero de projecto activos do cliente
    var pa;
    proj.arquivado ? (pa = 1) : (pa = -1);
    const { clients } = this.props.client;
    clients.filter(client =>
      client.name === proj.cliente
        ? this.props.updateClient(client._id, {
            ...client,
            projectosActivos: client.projectosActivos + pa
          })
        : null
    );

    // Actualiza projecto (arquiva ou desarquiva)
    const updatedItem = {
      ...proj,
      arquivado: !proj.arquivado
    };
    this.props.updateItem(proj._id, updatedItem);

    // Close modal
    if (!proj.arquivado) this.toggle();
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    return (
      <div>
        {this.props.isAuthenticated ? (
          // Se o projecto não estiver arquivado
          !this.props.proj.arquivado ? (
            // Se o utilizador for um cliente
            this.props.user.tipo === "Cliente" ? (
              <Button
                disabled
                outline
                className="btn ml-1"
                color="secondary"
                size="sm"
              >
                <FontAwesomeIcon icon={faArchive} />
              </Button>
            ) : (
              // Se o utilizador não for um cliente
              <Button
                className="btn ml-1"
                color="warning"
                size="sm"
                onMouseDown={this.toggle}
              >
                <FontAwesomeIcon icon={faArchive} inverse />
              </Button>
            )
          ) : // Se o projecto estiver arquivado
          // Se o utilizador for um cliente
          this.props.user.tipo === "Cliente" ? (
            <Button
              disabled
              outline
              className="btn ml-1"
              color="secondary"
              size="sm"
            >
              <FontAwesomeIcon icon={faUndoAlt} />
            </Button>
          ) : (
            // Se o utilizador não for um cliente
            <Button
              className="btn ml-1"
              color="success"
              size="sm"
              onMouseDown={this.onArchiveClick.bind(this, this.props.proj)}
            >
              <FontAwesomeIcon icon={faUndoAlt} inverse />
            </Button>
          )
        ) : null}

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Arquivar {this.props.track}?
          </ModalHeader>
          <ModalBody>
            <p>
              Está prestes a arquivar o projecto{" "}
              <strong>{this.props.proj.name}</strong>.
            </p>
            <p>Tem a certeza que quer continuar?</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.toggle}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={this.onArchiveClick.bind(this, this.props.proj)}
            >
              Arquivar
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  item: state.item,
  client: state.client,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps, { updateItem, updateClient })(
  ArquivarProjecto
);
