import {
  GET_ESTADOS,
  GET_ONE_ESTADO,
  ADD_ESTADO,
  UPDATE_ESTADO,
  DELETE_ESTADO,
  ESTADOS_LOADING
} from "../actions/types";

const initialState = {
  estados: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ESTADOS:
      return {
        ...state,
        estados: action.payload,
        loading: false
      };
    case GET_ONE_ESTADO:
      return {
        ...state,
        estados: action.payload,
        loading: false
      };
    case DELETE_ESTADO:
      return {
        ...state,
        estados: state.estados.filter(estado => estado._id !== action.payload)
      };
    case ADD_ESTADO:
      return {
        ...state,
        estados: [action.payload, ...state.estados]
      };
    case UPDATE_ESTADO:
      return {
        ...state,
        estados: state.estados.map(estado =>
          estado._id === action.payload._id ? action.payload : estado
        )
      };
    case ESTADOS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
