import React, { Component } from "react";
import Estado from "./Estado/Estado";
import Data from "./Data/Data";
import { Row, ListGroupItem } from "reactstrap";
import ApagarProjecto from "./ApagarProjecto/ApagarProjecto";
import ArquivarProjecto from "./ArquivarProjecto/ArquivarProjecto";
import "./Projecto.css";

export default class Projecto extends Component {
  render() {
    return (
      <ListGroupItem>
        <Row className="projecto">
          {/* Botões Acção */}
          <div className="accao">
            {/* Botão Apagar */}
            <ApagarProjecto
              id={this.props.id}
              proj={this.props.proj}
              track="projecto"
            />

            {/* Botão Arquivar */}
            <ArquivarProjecto proj={this.props.proj} track="projecto" />
          </div>

          {/* Nome do Projecto */}
          {/* Se estiver arquivado, aparece o nome rasurado e esbatido */}
          <div className="nomeProjecto">
            {!this.props.proj.arquivado ? (
              <label htmlFor="" className="px-3 mb-0">
                {this.props.name}
              </label>
            ) : (
              <label htmlFor="" className="px-3 mb-0 cinza">
                <del>{this.props.name}</del>
              </label>
            )}
          </div>

          {/* Datas */}
          <div className="datas">
            <Data proj={this.props.proj} />
          </div>

          {/* Estado (Ponto da situação) */}
          <div className="estadoButton">
            <Estado proj={this.props.proj} />
          </div>
        </Row>
      </ListGroupItem>
    );
  }
}
