import { combineReducers } from "redux";
import itemReducer from "./itemReducer";
import clientReducer from "./clientReducer";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import filtroReducer from "./filtroReducer";
import arquivadosReducer from "./arquivadosReducer";
import estadoReducer from "./estadoReducer";

const appReducer = combineReducers({
  item: itemReducer,
  client: clientReducer,
  error: errorReducer,
  auth: authReducer,
  filtro: filtroReducer,
  arquivados: arquivadosReducer,
  estado: estadoReducer
});

export default (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    state = undefined;
  }
  return appReducer(state, action);
};
