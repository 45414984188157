import { GET_ERRORS, CLEAR_ERRORS, AUTH_ERROR } from "./types";

// RETURN ERRORS
export const returnErrors = (msg, status, id = null) => {
  if (status === 408) {
    return {
      type: AUTH_ERROR
    };
  } else {
    return {
      type: GET_ERRORS,
      payload: { msg, status, id }
    };
  }
};

// CLEAR ERRORS
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
