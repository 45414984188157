import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { deleteClient } from "../../../../actions/clientActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "./ApagarCliente.css";

class ApagarCliente extends Component {
  state = {
    modal: false
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool
  };

  onDeleteClick = (id, projectosActivos) => {
    if (projectosActivos === 0) {
      this.props.deleteClient(id);
      this.props.erro(false);
      this.toggle();
    } else {
      this.props.erro(true);
      this.toggle();
    }
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    return (
      <div>
        {this.props.isAuthenticated ? (
          <Button
            className="btn ml-2"
            color="danger"
            size="sm"
            onClick={this.toggle}
          >
            <FontAwesomeIcon icon={faTrashAlt} inverse />
          </Button>
        ) : null}

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Apagar {this.props.track}?
          </ModalHeader>
          <ModalBody>
            <p>Está prestes a apagar este {this.props.track}.</p>
            <p>
              Este processo é irreversivel. Tem a certeza que quer continuar?
            </p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.toggle}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={this.onDeleteClick.bind(
                this,
                this.props.id,
                this.props.projectosActivos
              )}
            >
              Apagar
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  client: state.client,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { deleteClient })(ApagarCliente);
