import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  NavLink,
  Input,
  Alert
} from "reactstrap";
import { connect } from "react-redux";
import { addClient } from "../../../actions/clientActions";
import PropTypes from "prop-types";
import "./CriarClienteModal.css";

class CriarCliente extends Component {
  state = {
    modal: false,
    name: "",
    msg: null
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      name: "",
      msg: null
    });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { clients } = this.props.client;

    if (this.state.name === "") {
      this.setState({
        msg: "O nome do cliente é obrigatório"
      });
    } else {
      var exists = clients.filter(
        client => client.name.toLowerCase() === this.state.name.toLowerCase()
      );
      if (exists.length) {
        this.setState({
          msg: "O cliente introduzido já existe"
        });
      } else {
        const newItem = {
          name: this.state.name
        };

        // Adicionar cliente via Actions
        this.props.addClient(newItem);

        // Close modal
        this.toggle();
      }
    }
  };

  render() {
    return (
      <div>
        {this.props.isAuthenticated ? (
          <React.Fragment>
            <NavLink onClick={this.toggle} href="#">
              Criar Cliente
            </NavLink>
          </React.Fragment>
        ) : null}

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Adicionar cliente</ModalHeader>
          <ModalBody>
            {this.state.msg ? (
              <Alert color="danger">{this.state.msg}</Alert>
            ) : null}
            <Form onSubmit={this.onSubmit}>
              <FormGroup>
                <Label for="client">Cliente</Label>
                <Input
                  type="text"
                  name="name"
                  id="client"
                  placeholder="Adicionar cliente"
                  onChange={this.onChange}
                />
                <Button color="dark" className="criarButton" block>
                  Adicionar
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  client: state.client,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { addClient })(CriarCliente);
