import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { getClients, getOneClient } from "../../../actions/clientActions";
import PropTypes from "prop-types";
import ApagarCliente from "./ApagarCliente/ApagarCliente";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
  NavLink,
  Row,
  Alert
} from "reactstrap";
import "./ListarClientesModal.css";

class ListarClientes extends Component {
  state = {
    modal: false,
    msg: null
  };

  static propTypes = {
    getClients: PropTypes.func.isRequired,
    getOneClient: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool
  };

  componentDidMount() {
    if (this.props.user.tipo === "Cliente") {
      this.props.getOneClient(this.props.user.name);
    } else {
      this.props.getClients();
    }
  }

  mostrarErro = erro => {
    if (erro) {
      this.setState({
        msg: "Não pode apagar um cliente com projectos activos"
      });
    } else {
      this.setState({ msg: null });
    }
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      msg: null
    });
  };

  render() {
    const { clients } = this.props.client;
    return (
      <div>
        {this.props.isAuthenticated &&
        (this.props.user.tipo === "Administrador" ||
          this.props.user.tipo === "Editor") ? (
          <React.Fragment>
            <NavLink onClick={this.toggle} href="#">
              Listar Clientes
            </NavLink>
          </React.Fragment>
        ) : null}

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Clientes:</ModalHeader>
          <ModalBody>
            {this.state.msg ? (
              <Alert color="danger">{this.state.msg}</Alert>
            ) : null}
            {this.props.isAuthenticated ? (
              <ListGroup style={{ clear: "both" }}>
                <TransitionGroup className="client-list">
                  {clients.map(({ _id, name, projectosActivos }) => (
                    <CSSTransition key={_id} timeout={500} classNames="fade">
                      <ListGroupItem>
                        <Row>
                          {/* Botão Apagar */}
                          <div className="">
                            <ApagarCliente
                              id={_id}
                              track="cliente"
                              projectosActivos={projectosActivos}
                              erro={this.mostrarErro}
                            />
                          </div>
                          <div className="ml-3 py-1 mb-0">{name}</div>
                        </Row>
                      </ListGroupItem>
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </ListGroup>
            ) : null}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  client: state.client,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  getClients,
  getOneClient
})(ListarClientes);
