import React, { Component } from "react";
import { connect } from "react-redux";
import { updateItem } from "../../../../actions/itemActions";
import PropTypes from "prop-types";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import "./Estado.css";

class Estado extends Component {
  static propTypes = {
    updateItem: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    estado: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  handleClick(event, proj) {
    const updatedItem = {
      ...proj,
      estado: event.target.innerText
    };
    this.props.updateItem(proj._id, updatedItem);
  }

  render() {
    const { estados } = this.props.estado;

    return (
      <div>
        <UncontrolledDropdown size="sm">
          <DropdownToggle
            className="estado"
            disabled={this.props.proj.arquivado ? true : false}
            style={{
              backgroundColor: !this.props.proj.arquivado
                ? estados
                    .filter(estado => estado.name === this.props.proj.estado)
                    .map(estado => estado.backgroundColor)
                : "white",
              color: !this.props.proj.arquivado
                ? estados
                    .filter(estado => estado.name === this.props.proj.estado)
                    .map(estado => estado.color)
                : "#6c757d",
              justifyContent:
                this.props.user.tipo !== "Cliente" ? "space-between" : "center"
            }}
          >
            {this.props.proj.estado}
            {this.props.user.tipo !== "Cliente" ? (
              <FontAwesomeIcon icon={faCaretDown} />
            ) : null}
          </DropdownToggle>
          {this.props.user.tipo !== "Cliente" ? (
            <DropdownMenu right>
              {estados.map(estado => (
                <DropdownItem
                  key={estado._id}
                  className="text-right"
                  onClick={event =>
                    this.handleClick.bind(this)(event, this.props.proj)
                  }
                >
                  {estado.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          ) : null}
        </UncontrolledDropdown>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  item: state.item,
  estado: state.estado,
  user: state.auth.user
});

export default connect(mapStateToProps, { updateItem })(Estado);
