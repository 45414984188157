import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndoAlt, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { updateFiltro, deleteFiltro } from "../../../actions/showActions";
import "./FiltroCliente.css";

class FiltroCliente extends Component {
  state = {
    filtro: "Filtrar por cliente"
  };

  static propTypes = {
    clientes: PropTypes.object.isRequired
  };

  handleClick(event, cliente) {
    this.setState({
      filtro: cliente.name
    });
    this.props.updateFiltro(cliente.name);
  }

  undo() {
    this.setState({
      filtro: "Filtrar por cliente"
    });
    this.props.deleteFiltro();
  }

  render() {
    const { clients } = this.props.clientes;
    return (
      <div className="filtro">
        <UncontrolledDropdown size="sm" className="">
          <DropdownToggle className="caixaFiltro" outline color="secondary">
            {this.state.filtro}
            <FontAwesomeIcon icon={faCaretDown} className="my-auto" />
          </DropdownToggle>
          <DropdownMenu>
            {clients.map(cliente => (
              <DropdownItem
                key={cliente._id}
                onClick={event => this.handleClick.bind(this)(event, cliente)}
              >
                {cliente.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <Button
          className="btn ml-1"
          color="secondary"
          outline
          size="sm"
          onClick={this.undo.bind(this)}
        >
          <FontAwesomeIcon icon={faUndoAlt} />
        </Button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clientes: state.client
});

export default connect(mapStateToProps, { updateFiltro, deleteFiltro })(
  FiltroCliente
);
