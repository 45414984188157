import { ARCHIVE_ITEM } from '../actions/types';

const initialState = {
  arquivados: false
}

export default function(state = initialState, action) {
  switch(action.type) {
    case ARCHIVE_ITEM:
        return {
            arquivados: !state.arquivados
        };
    default:
      return state;
  }
}